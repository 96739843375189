<template>
  <div class="relative flex-grow bg-gray-100 pb-16">
    <div class="mx-auto flex h-full max-w-7xl flex-col gap-6 p-6 lg:flex-row">
      <div class="order-1 flex w-full flex-1 flex-col gap-4 lg:order-2">
        <StatsInline class="hidden lg:block" />
        <div class="hidden w-full border-t border-gray-300 lg:block"></div>
        <ActuatorsGrid />
        <ActuatorLogsModal />
        <ActuatorScheduleModal />
      </div>
      <div class="order-2 flex flex-col lg:order-1 lg:w-96">
        <aside
          class="flex w-full flex-col overflow-y-scroll rounded-lg bg-white shadow"
        >
          <ActuatorsAlerts />
        </aside>
      </div>
    </div>
    <div class="absolute inset-x-0 bottom-0 bg-gray-100 py-4 text-center">
      <p class="text-gray-600">Actualizado a las {{ lastUpdatedActuators }}</p>
    </div>
  </div>
</template>

<script>
import StatsInline from "./StatsInline.vue";
import ActuatorsGrid from "./ActuatorsGrid.vue";
import ActuatorLogsModal from "./ActuatorLogsModal.vue";
import ActuatorsAlerts from "./ActuatorsAlerts.vue";
import ActuatorScheduleModal from "./ActuatorScheduleModal.vue";

export default {
  name: "ActuatorsDashboard",
  components: {
    StatsInline,
    ActuatorsGrid,
    ActuatorLogsModal,
    ActuatorsAlerts,
    ActuatorScheduleModal,
  },
  computed: {
    lastUpdatedActuators() {
      return this.$store.state.lastUpdatedActuators;
    },
  },
};
</script>
