<template>
  <div
    class="flex items-center justify-between hover:bg-gray-100"
    :class="{ 'bg-gray-100': isSelected }"
  >
    <span class="truncate">{{ name }}</span>
    <span class="ml-2 hidden items-center gap-1 text-gray-400 md:flex">
      {{ sensorCount }}
      <font-awesome-icon icon="wifi" class="h-4 w-4" />
    </span>
  </div>
</template>

<script>
export default {
  name: "VenueLink",
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    sensorCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isSelected() {
      return this.id === this.$store.state.currentVenue.id;
    },
  },
};
</script>
