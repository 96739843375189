<template>
  <Listbox
    v-model="selectedOption"
    as="div"
    class="flex w-full justify-center"
    :disabled="loading"
  >
    <ListboxLabel class="sr-only">Change setpoint preset</ListboxLabel>
    <div class="relative w-full">
      <ListboxButton
        class="flex w-full items-center justify-center gap-2 py-2 hover:bg-gray-50 active:bg-gray-100"
      >
        <span class="sr-only">Change setpoint preset</span>
        <p
          class="break-words px-2 text-center text-sm font-semibold text-gray-800"
        >
          {{ selectedOption.name }}
        </p>
        <ChevronDownIcon
          class="h-5 w-5 flex-shrink-0 text-gray-600"
          aria-hidden="true"
        />
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to="opacity-0"
      >
        <ListboxOptions
          class="absolute left-1/2 z-10 mt-2 w-[calc(100vw-2rem)] origin-top -translate-x-1/2 divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:w-72"
        >
          <ListboxOption
            v-for="option in allOptions"
            :key="option.id"
            v-slot="{ active, selected }"
            :value="option"
          >
            <li class="relative">
              <div
                :class="[
                  active ? 'bg-blue-600 text-white' : 'text-gray-900',
                  'flex cursor-pointer items-center justify-between px-4 py-4',
                ]"
              >
                <div class="flex flex-grow flex-col">
                  <div class="flex flex-wrap items-center gap-1">
                    <span class="break-words font-medium">
                      {{ option.name }}
                    </span>
                    <span
                      v-if="option.id !== 'manual' && option.id !== 'create'"
                      class="whitespace-nowrap text-sm"
                      :class="active ? 'text-blue-200' : 'text-gray-500'"
                    >
                      ({{ option.setpointTempLow }}° -
                      {{ option.setpointTempHigh }}°)
                    </span>
                  </div>
                  <p
                    v-if="option.description"
                    :class="[active ? 'text-blue-200' : 'text-gray-500']"
                    class="mt-1 break-words text-sm"
                  >
                    {{ option.description }}
                  </p>
                </div>

                <div class="ml-4 flex items-center">
                  <template
                    v-if="
                      option.id &&
                      option.id !== 'manual' &&
                      option.id !== 'create'
                    "
                  >
                    <button
                      class="rounded-full p-1 hover:bg-blue-700"
                      :class="
                        active
                          ? 'text-white'
                          : 'text-gray-400 hover:text-gray-500'
                      "
                      @click.stop="editPreset(option)"
                    >
                      <PencilIcon class="h-4 w-4" />
                    </button>
                    <button
                      class="ml-1 rounded-full p-1 hover:bg-blue-700"
                      :class="
                        active
                          ? 'text-white'
                          : 'text-gray-400 hover:text-gray-500'
                      "
                      @click.stop="confirmDelete(option)"
                    >
                      <TrashIcon class="h-4 w-4" />
                    </button>
                  </template>

                  <template v-else>
                    <CheckIcon
                      v-if="selected"
                      class="h-5 w-5"
                      :class="active ? 'text-white' : 'text-blue-600'"
                      aria-hidden="true"
                    />
                    <PlusIcon
                      v-else-if="option.id === 'create'"
                      class="h-6 w-6"
                      :class="active ? 'text-white' : 'text-blue-600'"
                      aria-hidden="true"
                    />
                  </template>
                </div>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>

  <CreateSetpointPresetModal
    :is-open="showModal"
    :preset="selectedPreset"
    @close="closeModal"
    @create="createPreset"
    @update="updatePreset"
  />
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import {
  ChevronDownIcon,
  PencilIcon,
  TrashIcon,
  PlusIcon,
  CheckIcon,
} from "@heroicons/vue/24/outline";
import CreateSetpointPresetModal from "./CreateSetpointPresetModal.vue";

export default {
  name: "SetpointPresetSelectMenu",
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    ChevronDownIcon,
    PencilIcon,
    TrashIcon,
    PlusIcon,
    CheckIcon,
    CreateSetpointPresetModal,
  },
  props: {
    actuatorIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ["loadingChanged", "setpointModeChanged"],
  data() {
    return {
      loading: false,
      showModal: false,
      selectedPreset: null,
    };
  },
  computed: {
    actuator() {
      return this.$store.state.venueActuators[this.actuatorIndex];
    },
    setpointPresets() {
      return this.$store.state.setpointPresets;
    },
    setpointPresetOptions() {
      const currentPresetUuid = this.actuator.currentSetpointPresetUuid;
      const currentPreset = this.setpointPresets[currentPresetUuid];

      let options = this.actuator.availableSetpointPresetUuids
        .filter((id) => id !== currentPresetUuid)
        .map((id) => this.setpointPresets[id])
        .sort((a, b) => a.name.localeCompare(b.name));

      if (currentPreset) {
        options.unshift(currentPreset);
      }

      return options;
    },
    allOptions() {
      return [
        {
          id: "manual",
          name: "Manual",
          description: "Selecciona los setpoints manualmente",
        },
        ...this.setpointPresetOptions,
        {
          id: "create",
          name: "Crear",
          description: "Crea un nuevo preset de setpoints",
        },
      ];
    },
    selectedOption: {
      get() {
        if (this.actuator.setpointMode === "manual") {
          return { id: "manual", name: "Manual" };
        }
        return (
          this.setpointPresets[this.actuator.currentSetpointPresetUuid] || {
            id: "manual",
            name: "Manual",
          }
        );
      },
      set(option) {
        if (option.id === "manual") {
          this.updateActuatorSettings({ setpointMode: "manual" });
        } else if (option.id === "create") {
          this.selectedPreset = null;
          this.showModal = true;
        } else {
          this.updateActuatorSettings({
            setpointMode: "preset",
            currentSetpointPresetUuid: option.id,
          });
        }
      },
    },
  },
  methods: {
    updateActuatorSettings(settings) {
      this.loading = true;
      this.$emit("loadingChanged", true);

      this.$store
        .dispatch("updateActuator", {
          id: this.actuator.id,
          data: settings,
        })
        .then(() => {
          this.loading = false;
          this.$emit("loadingChanged", false);
          if (settings.setpointMode) {
            this.$emit("setpointModeChanged", settings.setpointMode);
          }
        });
    },
    createPreset(preset) {
      this.loading = true;
      this.$emit("loadingChanged", true);

      this.$store
        .dispatch("createSetpointPreset", {
          id: this.actuator.id,
          data: preset,
        })
        .then(() => {
          this.showModal = false;
        });
    },
    editPreset(preset) {
      this.selectedPreset = preset;
      this.showModal = true;
    },
    async updatePreset(updatedData) {
      this.loading = true;
      this.$emit("loadingChanged", true);

      try {
        await this.$store.dispatch("updateSetpointPreset", {
          id: this.selectedPreset.id,
          data: updatedData,
        });
        this.showModal = false;
      } finally {
        this.loading = false;
        this.$emit("loadingChanged", false);
      }
    },
    async confirmDelete(preset) {
      if (
        confirm(
          `¿Estás seguro de que deseas eliminar el preset "${preset.name}"?`
        )
      ) {
        this.loading = true;
        this.$emit("loadingChanged", true);

        try {
          await this.$store.dispatch("deleteSetpointPreset", preset.id);
        } finally {
          this.loading = false;
          this.$emit("loadingChanged", false);
        }
      }
    },
    closeModal() {
      this.showModal = false;
      this.selectedPreset = null;
    },
  },
};
</script>
