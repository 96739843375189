<template>
  <div
    v-if="loadingActuators"
    class="flex h-full flex-col items-center justify-center"
  >
    <font-awesome-icon
      icon="spinner"
      class="h-12 w-12 animate-spin text-gray-600"
    />
  </div>
  <div v-else-if="actuatorCount" class="flex flex-col gap-6">
    <ul
      ref="grid"
      role="list"
      class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2"
    >
      <li v-for="index in actuatorCount" :key="index" class="col-span-1">
        <ActuatorsGridItem :actuator-index="index - 1" />
      </li>
    </ul>
  </div>
  <div v-else class="flex h-full flex-col items-center justify-center gap-4">
    <font-awesome-icon icon="inbox" class="h-24 w-24 text-gray-600" />
    <p class="text-lg font-semibold text-gray-800">
      No tienes actuadores en este recinto
    </p>
  </div>
</template>

<script>
import ActuatorsGridItem from "./ActuatorsGridItem.vue";

export default {
  name: "ActuatorsGrid",
  components: {
    ActuatorsGridItem,
  },
  computed: {
    actuatorCount() {
      return this.$store.state.venueActuators.length;
    },
    loadingActuators() {
      return this.$store.state.loadingActuators;
    },
  },
  watch: {
    loadingActuators(newValue) {
      if (!newValue) {
        this.updateGridHeight();
      }
    },
  },
  created() {
    this.$store.dispatch("loadVenueActuators");
  },
  mounted() {
    this.updateGridHeight();
  },
  methods: {
    updateGridHeight() {
      this.$nextTick(() => {
        const gridHeight = this.$el.clientHeight;
        this.$store.dispatch("updateActuatorsGridHeight", gridHeight);
      });
    },
    runVenueActuatorsControlCycle() {
      this.$store.dispatch("runVenueActuatorsControlCycle");
    },
  },
};
</script>
