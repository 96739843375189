<template>
  <div class="p-4">
    <div
      class="flex flex-col justify-around pt-2 text-center md:flex-row md:pt-5"
      style="position: relative; z-index: 999"
    >
      <div class="w-full text-center">
        <date-picker
          :disabled="loading"
          class="inline-flex justify-center"
          style="z-index: 999"
        />
      </div>
    </div>

    <div class="mt-4 text-center">
      <span class="text-gray-600">
        <template v-if="loading">
          <font-awesome-icon
            icon="spinner"
            class="mr-2 h-4 w-4 animate-spin text-gray-600"
          />
          Actualizando...
        </template>
        <template v-else> Actualizado a las {{ lastUpdatedSensors }} </template>
      </span>
    </div>

    <div
      class="relative mt-4 grid gap-4 md:grid-cols-2 xl:grid-cols-3"
      style="z-index: 1"
    >
      <modal
        v-if="isModalVisible"
        class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        @close-modal="closeModal"
        @in-modal="inModal"
      >
        <template #body>
          <div v-if="!loading" class="flex h-full w-full flex-col">
            <div class="mb-4 inline-flex justify-center">
              <p class="mr-2 text-3xl font-medium">
                {{ selectedSensor?.name }}
              </p>
            </div>
            <div class="flex h-full w-full flex-grow justify-center">
              <div class="flex h-full w-full flex-col">
                <div class="mt-3 flex justify-center">
                  <date-picker
                    :disabled="loading"
                    class="z-10 flex justify-center"
                  />
                </div>
                <distance-measurements-chart
                  v-if="selectedSensor"
                  :sensor-id="selectedSensor.pk"
                  class="mt-4"
                />
              </div>
            </div>
          </div>
          <div
            v-else
            class="flex h-full min-h-[33rem] flex-col items-center justify-center"
          >
            <font-awesome-icon
              icon="spinner"
              class="h-12 w-12 animate-spin text-gray-600"
            />
          </div>
        </template>
      </modal>

      <div
        v-for="sensor in sensors"
        :key="sensor.pk"
        class="group relative overflow-hidden rounded-lg bg-white p-6 shadow-md transition-all duration-300 hover:shadow-lg"
      >
        <div class="mb-6 flex items-center justify-between">
          <h3 class="text-xl font-semibold text-gray-800">{{ sensor.name }}</h3>
          <span
            class="rounded-full px-4 py-1.5 text-sm font-medium"
            :class="getStatusClass(sensor.values?.position)"
          >
            {{ formatPosition(sensor.values?.position) }}
          </span>
        </div>

        <div v-if="sensor.values" class="space-y-4">
          <div class="flex items-center justify-between">
            <span class="text-gray-600">Distancia:</span>
            <div class="flex items-baseline">
              <span class="text-2xl font-bold text-gray-800">
                {{ sensor.values.distance }}
              </span>
              <span class="ml-1 text-sm text-gray-500">mm</span>
            </div>
          </div>
          <div class="flex items-center justify-between border-t pt-4 text-sm">
            <span class="text-gray-600">Última medición:</span>
            <span class="text-gray-500">
              {{ formatTimestamp(sensor.values.timestamp) }}
            </span>
          </div>
        </div>

        <div v-else class="py-8 text-center text-gray-500">
          Sin datos disponibles
        </div>

        <button
          class="mt-6 flex w-full items-center justify-between rounded-lg bg-gray-50 px-4 py-3 text-sm font-medium text-gray-700 transition-colors hover:bg-gray-100"
          @click="selectSensor(sensor)"
        >
          <span>Ver Gráfico</span>
          <font-awesome-icon icon="arrow-right" class="text-gray-400" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Modal from "../cards/Modal.vue";
import DatePicker from "../dashboard/DatePicker.vue";
import DistanceMeasurementsChart from "./DistanceMeasurementsChart.vue";
import { listenerMixin } from "../../mixins";

export default {
  name: "DistanceView",

  components: {
    Modal,
    DatePicker,
    DistanceMeasurementsChart,
  },

  mixins: [listenerMixin],

  data() {
    return {
      selectedSensor: null,
      isModalVisible: false,
      loading: false,
      inside: false,
      sensors: [],
      updateInterval: null,
    };
  },

  computed: {
    selectedDate() {
      return this.$store.state.selectedDate;
    },
    lastUpdatedSensors() {
      return this.$store.state.lastUpdatedSensors;
    },
    isToday() {
      return this.selectedDate.isSame(moment(), "day");
    },
  },

  watch: {
    selectedDate: {
      async handler() {
        await this.loadSensors();
      },
      deep: true,
    },
  },

  async created() {
    if (!this.$store.state.selectedDate) {
      this.$store.commit("setSelectedDate", moment());
    }
    await this.loadSensors();

    // Set up auto-update interval
    this.updateInterval = setInterval(async () => {
      if (this.isToday) {
        await this.loadSensors();
      }
    }, 120000); // 2 minutes in milliseconds
  },

  beforeUnmount() {
    // Clear the interval when component is destroyed
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  },

  methods: {
    async loadSensors() {
      try {
        this.loading = true;
        await this.$store.dispatch("loadVenueGrid");
        this.sensors =
          this.$store.state.venueGrid.sensors?.filter(
            (sensor) => sensor.brand === "em310-udl"
          ) || [];
      } catch (error) {
        console.error("Error loading sensors:", error);
      } finally {
        this.loading = false;
      }
    },

    formatTimestamp(timestamp) {
      return moment(timestamp).fromNow();
    },

    formatPosition(position) {
      const positions = {
        normal: "Normal",
        tilt: "Inclinado",
      };
      return positions[position] || "Desconocido";
    },

    getStatusClass(position) {
      const classes = {
        normal: "bg-green-50 text-green-700",
        tilt: "bg-red-50 text-red-700",
      };
      return classes[position] || "bg-gray-50 text-gray-700";
    },

    async selectSensor(sensor) {
      try {
        this.loading = true;
        this.selectedSensor = sensor;
        this.isModalVisible = true;
        await this.$store.dispatch("selectSensor", { id: sensor.pk });
      } catch (error) {
        console.error("Error selecting sensor:", error);
      } finally {
        this.loading = false;
      }
    },

    inModal() {
      this.inside = true;
    },

    closeModal() {
      this.isModalVisible = false;
      this.inside = false;
      this.selectedSensor = null;
    },
  },
};
</script>
