<template>
  <div
    ref="alerts"
    class="flex flex-col bg-white transition-all duration-300"
    :style="{
      height: hasExpandedGroups ? combinedHeight + 'px' : 'auto',
      maxHeight: hasExpandedGroups ? combinedHeight + 'px' : 'auto',
    }"
  >
    <div class="sticky top-0 z-10 bg-white">
      <div
        class="flex items-center justify-between border-b border-gray-300 p-5"
      >
        <h3 class="text-lg font-semibold text-gray-900">Alertas</h3>
        <button
          v-if="showCloseButton"
          class="text-gray-500 hover:text-gray-700"
          aria-label="Close Alerts"
          @click="$emit('close')"
        >
          <font-awesome-icon
            icon="xmark"
            class="h-4 w-4 text-gray-400 hover:text-gray-500"
          />
        </button>
      </div>

      <div class="border-b border-gray-300">
        <multiple-select
          :options="allActuators"
          :initial-selection="selectedActuators"
          @update:selection="updateSelection"
        />
        <div class="flex items-center gap-2 bg-gray-50 p-3">
          <span class="text-sm text-gray-600">Severidad:</span>
          <div class="flex gap-2">
            <button
              v-for="severity in severityOptions"
              :key="severity.id"
              :class="[
                'rounded-full px-3 py-1 text-xs font-medium transition-colors',
                selectedSeverities.includes(severity.id)
                  ? severity.color
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200',
              ]"
              @click="toggleSeverity(severity.id)"
            >
              {{ severity.name }}
            </button>
          </div>
        </div>
      </div>

      <div class="flex border-b border-gray-300">
        <button
          :class="{
            'border-b-2 border-blue-500 text-blue-600': currentTab === 'new',
            'text-gray-500': currentTab !== 'new',
          }"
          class="flex-1 p-4 text-center text-sm font-medium hover:text-gray-700 focus:outline-none"
          @click="changeTab('new')"
        >
          Principal
        </button>
        <button
          :class="{
            'border-b-2 border-blue-500 text-blue-600':
              currentTab === 'resolved',
            'text-gray-500': currentTab !== 'resolved',
          }"
          class="flex-1 p-4 text-center text-sm font-medium hover:text-gray-700 focus:outline-none"
          @click="changeTab('resolved')"
        >
          Resueltas
        </button>
        <button
          :class="{
            'border-b-2 border-blue-500 text-blue-600':
              currentTab === 'discarded',
            'text-gray-500': currentTab !== 'discarded',
          }"
          class="flex-1 p-4 text-center text-sm font-medium hover:text-gray-700 focus:outline-none"
          @click="changeTab('discarded')"
        >
          Descartadas
        </button>
      </div>
    </div>

    <div
      class="relative overflow-y-auto"
      :style="{
        maxHeight: hasExpandedGroups ? 'calc(100% - 10rem)' : 'auto',
      }"
    >
      <div v-if="isLoading">
        <div class="flex items-center justify-center p-10">
          <font-awesome-icon
            icon="spinner"
            class="h-6 w-6 animate-spin text-gray-400"
          />
        </div>
      </div>

      <div v-else-if="groupedAlerts.length === 0">
        <div class="flex items-center justify-center p-10">
          <div
            v-if="isLoading"
            class="flex flex-col items-center justify-center gap-2"
          >
            <font-awesome-icon
              icon="spinner"
              class="h-8 w-8 animate-spin text-gray-400"
            />
            <span class="mt-2 text-sm text-gray-500">Cargando alertas...</span>
          </div>
          <div
            v-else
            class="flex flex-col items-center justify-center space-y-2 p-4"
          >
            <span class="text-gray-500">No hay alertas disponibles</span>
            <button
              class="text-sm text-blue-500 hover:text-blue-700"
              @click="loadAlerts"
            >
              Intentar nuevamente
            </button>
          </div>
        </div>
      </div>

      <div v-else class="space-y-4 p-4">
        <div
          v-for="(group, groupIdx) in groupedAlerts"
          :key="groupIdx"
          class="rounded-lg border border-gray-200 bg-white"
        >
          <button
            class="flex w-full items-center justify-between px-4 py-4 hover:bg-gray-50"
            @click="toggleGroup(group.actuatorId)"
          >
            <div class="flex items-center space-x-3">
              <span class="font-medium text-gray-900">{{
                group.actuatorName
              }}</span>
              <div
                v-if="group.hasAlerts"
                class="relative flex h-5 cursor-pointer items-center"
              >
                <div
                  class="flex h-5 items-center overflow-hidden rounded-full bg-white shadow-sm"
                >
                  <template v-if="group.hasAlerts">
                    <template v-if="group.alertCountsBySeverity[3] > 0">
                      <div
                        class="flex h-5 w-4 items-center justify-start bg-red-500 pl-1.5"
                      >
                        <font-awesome-icon
                          :icon="alertIcon"
                          class="h-2.5 w-2.5 text-white"
                        />
                      </div>
                      <div
                        class="flex h-5 min-w-[1.25rem] items-center justify-center bg-red-500 pr-1.5 text-xs font-medium text-white"
                      >
                        {{ group.alertCountsBySeverity[3] }}
                      </div>
                      <div
                        v-if="group.alertCountsBySeverity[2] > 0"
                        class="-ml-1 flex h-5 min-w-[1.25rem] items-center justify-center bg-orange-500 pr-1 text-xs font-medium text-white"
                      >
                        {{ group.alertCountsBySeverity[2] }}
                      </div>
                      <div
                        v-if="group.alertCountsBySeverity[1] > 0"
                        class="-ml-1 flex h-5 min-w-[1.25rem] items-center justify-center bg-yellow-400 pr-1 text-xs font-medium text-white"
                      >
                        {{ group.alertCountsBySeverity[1] }}
                      </div>
                    </template>

                    <template v-else-if="group.alertCountsBySeverity[2] > 0">
                      <div
                        class="flex h-5 w-4 items-center justify-start bg-orange-500 pl-1.5"
                      >
                        <font-awesome-icon
                          :icon="alertIcon"
                          class="h-2.5 w-2.5 text-white"
                        />
                      </div>
                      <div
                        class="flex h-5 min-w-[1.25rem] items-center justify-center bg-orange-500 pr-1.5 text-xs font-medium text-white"
                      >
                        {{ group.alertCountsBySeverity[2] }}
                      </div>
                      <div
                        v-if="group.alertCountsBySeverity[1] > 0"
                        class="-ml-1 flex h-5 min-w-[1.25rem] items-center justify-center bg-yellow-400 pr-1 text-xs font-medium text-white"
                      >
                        {{ group.alertCountsBySeverity[1] }}
                      </div>
                    </template>

                    <template v-else-if="group.alertCountsBySeverity[1] > 0">
                      <div
                        class="flex h-5 w-4 items-center justify-start bg-yellow-400 pl-1.5"
                      >
                        <font-awesome-icon
                          :icon="alertIcon"
                          class="h-2.5 w-2.5 text-white"
                        />
                      </div>
                      <div
                        class="flex h-5 min-w-[1.25rem] items-center justify-center bg-yellow-400 pr-1.5 text-xs font-medium text-white"
                      >
                        {{ group.alertCountsBySeverity[1] }}
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
            <span
              :class="[
                'inline-block h-5 w-5 text-gray-500 transition-transform duration-200',
                expandedGroups[group.actuatorId] ? 'rotate-180' : '',
              ]"
            >
              ▼
            </span>
          </button>

          <!-- Alerts Container with animation -->
          <div
            v-show="expandedGroups[group.actuatorId]"
            class="overflow-hidden transition-all duration-300 ease-in-out"
            :style="{
              maxHeight: expandedGroups[group.actuatorId]
                ? `calc(${combinedHeight}px - 12rem)`
                : '0',
              opacity: expandedGroups[group.actuatorId] ? '1' : '0',
            }"
          >
            <div
              class="overflow-y-auto border-t border-gray-200 bg-gray-50 p-4"
            >
              <ul class="space-y-4">
                <li
                  v-for="(event, eventIdx) in group.displayedAlerts"
                  :key="eventIdx"
                  :class="[
                    'relative rounded-lg border p-4',
                    event.text.severityLevel === 3
                      ? 'border-red-200 bg-red-50'
                      : event.text.severityLevel === 2
                      ? 'border-orange-200 bg-orange-50'
                      : 'border-yellow-200 bg-yellow-50',
                  ]"
                >
                  <!-- Alert Header -->
                  <div class="mb-3 flex items-center justify-between">
                    <div class="flex items-center gap-3">
                      <span
                        :class="[
                          event.iconBackground,
                          'flex h-8 w-8 items-center justify-center rounded-full',
                        ]"
                      >
                        <font-awesome-icon
                          :icon="event.icon"
                          class="h-5 w-5 text-white"
                        />
                      </span>
                      <div>
                        <div class="flex items-center gap-2">
                          <span class="font-medium text-gray-900">{{
                            event.text.name
                          }}</span>
                          <span class="text-sm text-gray-600">{{
                            event.text.system
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <span
                      :class="[
                        'rounded-full px-2 py-1 text-xs font-medium',
                        event.text.severityLevel === 3
                          ? 'bg-red-100 text-red-800'
                          : event.text.severityLevel === 2
                          ? 'bg-orange-100 text-orange-800'
                          : 'bg-yellow-100 text-yellow-800',
                      ]"
                    >
                      {{
                        event.text.severityLevel === 3
                          ? "CRÍTICO"
                          : event.text.severityLevel === 2
                          ? "ADVERTENCIA"
                          : "AVISO"
                      }}
                    </span>
                  </div>

                  <!-- Alert Content -->
                  <div class="space-y-2">
                    <p class="text-sm text-gray-700">
                      <span class="font-medium">Estado:</span>
                      {{ event.text.mainText }}
                    </p>
                    <p class="text-sm text-gray-600">
                      <span class="font-medium">Acción requerida:</span>
                      {{ event.text.subText }}
                    </p>
                  </div>

                  <!-- Alert Footer -->
                  <div
                    class="mt-3 flex items-center justify-between border-t border-gray-200 pt-3"
                  >
                    <div class="flex items-center gap-2 text-xs text-gray-500">
                      <font-awesome-icon icon="clock" class="h-3 w-3" />
                      <span
                        >{{ event.date }} {{ event.time }} ·
                        {{ event.duration }}</span
                      >
                    </div>

                    <div class="flex gap-2">
                      <template v-if="currentTab === 'new'">
                        <button
                          type="button"
                          title="Confirmar que alerta es real"
                          class="rounded-full p-1.5 text-green-600 hover:bg-green-50"
                          @click="
                            updateAlert(
                              event.actuator_id,
                              event.alerts[0].timestamp,
                              true
                            )
                          "
                        >
                          <font-awesome-icon icon="check" class="h-5 w-5" />
                        </button>
                        <button
                          type="button"
                          title="Alerta fue un error o ya fue resuelta"
                          class="rounded-full p-1.5 text-red-600 hover:bg-red-50"
                          @click="
                            updateAlert(
                              event.actuator_id,
                              event.alerts[0].timestamp,
                              false
                            )
                          "
                        >
                          <font-awesome-icon icon="times" class="h-5 w-5" />
                        </button>
                      </template>

                      <div v-else class="relative">
                        <button
                          type="button"
                          class="p-2 text-gray-500 hover:text-gray-700"
                          @click.stop="toggleMenu(eventIdx)"
                        >
                          <font-awesome-icon icon="ellipsis-v" />
                        </button>

                        <div
                          v-if="event.showMenu"
                          class="absolute bottom-0 right-0 z-50 mb-8 w-48 rounded-md bg-white shadow-lg"
                          @click.stop
                        >
                          <div class="py-1">
                            <button
                              class="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                              @click="
                                deleteAlert(event.actuator_id, event.alerts)
                              "
                            >
                              Eliminar
                            </button>
                            <button
                              class="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                              @click="
                                markAsUnresolved(
                                  event.actuator_id,
                                  event.alerts[0].timestamp
                                )
                              "
                            >
                              Marcar como no resuelta
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <!-- Show More Button -->
              <div
                v-if="group.alerts.length > group.displayLimit"
                class="mt-4 text-center"
              >
                <button
                  class="text-sm font-medium text-blue-600 hover:text-blue-700"
                  @click="loadMore(group.actuatorId)"
                >
                  Mostrar más
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultipleSelect from "../common/Multiselect.vue";
export default {
  name: "ActuatorsAlerts",
  components: { MultipleSelect },
  props: {
    initialActuatorId: {
      type: [Number, String],
      default: null,
    },
    initialActuatorName: {
      type: String,
      default: null,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "opened"],
  data() {
    return {
      alerts: [],
      pollInterval: null,
      currentTab: "new",
      selectedActuators: [],
      selectedActuatorsIds: [],
      selectedSeverities: [],
      isLoading: true,
      expandedGroups: {},
      displayLimits: {},
      alertsCache: {},
    };
  },
  computed: {
    alertIcon() {
      return "exclamation-circle";
    },
    severityOptions() {
      return [
        { id: 3, name: "CRÍTICO", color: "bg-red-100 text-red-800" },
        { id: 2, name: "ADVERTENCIA", color: "bg-orange-100 text-orange-800" },
        { id: 1, name: "AVISO", color: "bg-yellow-100 text-yellow-800" },
      ];
    },
    venueName() {
      return this.$store.state.currentVenue.name;
    },
    allActuators() {
      return this.$store.state.venueActuators;
    },
    hasExpandedGroups() {
      return Object.values(this.expandedGroups).some((value) => value === true);
    },
    filteredAlerts() {
      return this.alerts
        .filter(
          (alert) =>
            this.selectedActuatorsIds.length === 0 ||
            this.selectedActuatorsIds.includes(alert.actuator_id)
        )
        .filter(
          (alert) =>
            this.selectedSeverities.length === 0 ||
            this.selectedSeverities.includes(alert.text.severityLevel)
        )
        .filter((alert) => {
          if (this.currentTab === "new")
            return alert.correct === undefined || alert.correct === null;
          if (this.currentTab === "resolved") return alert.correct === true;
          if (this.currentTab === "discarded") return alert.correct === false;
          return true;
        });
    },
    groupedAlerts() {
      const groups = this.filteredAlerts.reduce((acc, alert) => {
        const actuatorId = alert.actuator_id;
        const actuator = this.allActuators.find((a) => a.id === actuatorId);
        const actuatorName = actuator ? actuator.name : "Cargando... ";

        if (!acc[actuatorId]) {
          acc[actuatorId] = {
            actuatorId,
            actuatorName,
            alerts: [],
            displayLimit: this.displayLimits[actuatorId] || 10,
            alertCountsBySeverity: { 1: 0, 2: 0, 3: 0 },
            hasAlerts: false,
          };
        }

        acc[actuatorId].alerts.push(alert);

        let severity = alert.severity;
        if (alert.alerts && alert.alerts.length > 0) {
          const mostRecentAlert = alert.alerts[alert.alerts.length - 1];
          severity = mostRecentAlert.severity;
        }
        if (!severity) {
          severity = this.getDefaultSeverity(alert.alertType);
        }
        severity = Math.max(1, Math.min(3, Number(severity)));
        acc[actuatorId].alertCountsBySeverity[severity] += 1;
        acc[actuatorId].hasAlerts =
          acc[actuatorId].alertCountsBySeverity[1] > 0 ||
          acc[actuatorId].alertCountsBySeverity[2] > 0 ||
          acc[actuatorId].alertCountsBySeverity[3] > 0;

        return acc;
      }, {});

      return Object.values(groups).map((group) => ({
        ...group,
        alerts: group.alerts.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        ),
        displayedAlerts: group.alerts.slice(0, group.displayLimit),
      }));
    },
    combinedHeight() {
      const gridHeight = this.$store.state.actuatorsGridHeight;
      const statsInlineHeight = this.$store.state.statsInlineHeight;
      const buffer = 26;
      return gridHeight + statsInlineHeight + buffer;
    },
  },
  watch: {
    "$store.state.currentVenue": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.alerts = [];
        this.loadAlerts();
      }
    },
    isOpen: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.loadAlerts();
        }
      },
    },
    $route(to, from) {
      if (to !== from) {
        this.loadAlerts();
      }
    },
    currentVenue: {
      handler() {
        this.loadAlerts();
      },
      deep: true,
    },
    currentTab() {
      this.closeAllMenus();
      this.resetGroups();
    },
  },
  async created() {
    this.isLoading = true;
    await this.loadAlerts();

    this.startPolling();
    document.addEventListener("click", this.handleClickOutside);

    if (this.initialActuatorName) {
      const match = this.allActuators.find(
        (a) => a.name === this.initialActuatorName
      );
      if (match) {
        this.selectedActuators = [match];
        this.selectedActuatorsIds = [match.id];
      }
    }
  },
  beforeUnmount() {
    this.stopPolling();
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    async loadAlertsWithCache() {
      const cacheKey = `alertsCache-${this.$store.state.currentVenue.id}`;
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        const now = new Date();
        const cacheAge = now - new Date(timestamp);

        if (cacheAge < 60000) {
          this.alerts = data;
          this.isLoading = false;
          return;
        }
      }

      await this.loadAlerts();

      const cacheData = {
        timestamp: new Date(),
        data: this.alerts,
      };
      localStorage.setItem(cacheKey, JSON.stringify(cacheData));
    },
    async loadAlerts() {
      try {
        this.isLoading = true;
        const alerts = this.$store.state.venueActuatorsAlerts.filter(
          (alert) => {
            if (this.initialActuatorName) {
              const actuator = this.allActuators.find(
                (a) => a.name === this.initialActuatorName
              );
              return actuator && alert.uuid === actuator.id;
            }
            return true;
          }
        );

        const processedAlerts = this.translateAlertsToTimeline(alerts);
        this.alerts = processedAlerts;
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 300);
      }
    },
    closeAlertsPopup() {
      this.showAlertsPopup = false;
      this.$emit("close");
    },
    initializeGroups() {
      const newExpandedGroups = {};
      const newDisplayLimits = {};

      this.allActuators.forEach((actuator) => {
        newExpandedGroups[actuator.id] = false;
        newDisplayLimits[actuator.id] = 10;
      });

      this.expandedGroups = newExpandedGroups;
      this.displayLimits = newDisplayLimits;
    },
    resetGroups() {
      const newExpandedGroups = {};
      const newDisplayLimits = {};

      Object.keys(this.expandedGroups).forEach((id) => {
        newExpandedGroups[id] = false;
        newDisplayLimits[id] = 10;
      });

      this.expandedGroups = newExpandedGroups;
      this.displayLimits = newDisplayLimits;
    },
    toggleGroup(actuatorId) {
      // Use object spread to trigger reactivity
      this.expandedGroups = {
        ...this.expandedGroups,
        [actuatorId]: !this.expandedGroups[actuatorId],
      };
    },
    loadMore(actuatorId) {
      const currentLimit = this.displayLimits[actuatorId] || 10;
      this.displayLimits = {
        ...this.displayLimits,
        [actuatorId]: currentLimit + 10,
      };
    },
    translateAlertsToTimeline(alerts) {
      return alerts.map((alert) => {
        let severityToUse;
        if (alert.alerts && alert.alerts.length > 0) {
          const mostRecentAlert = alert.alerts[alert.alerts.length - 1];
          severityToUse = mostRecentAlert.severity;
        } else {
          severityToUse =
            alert.severity || this.getDefaultSeverity(alert.alertType);
        }

        const translatedAlert = {
          text: this.getTextFromAlert({ ...alert, severity: severityToUse }),
          date: new Date(alert.timestamp).toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
          }),
          time: new Date(alert.timestamp).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
          icon: this.getIconFromAlertType(alert.alertType, severityToUse),
          iconBackground: this.getIconBackgroundFromAlertType(
            alert.alertType,
            severityToUse
          ),
          timestamp: alert.timestamp,
          duration: this.formatDuration(alert.duration),
          correct: alert.correct,
          actuator_id: alert.uuid,
          showMenu: alert.showMenu || false,
          alerts: alert.alerts,
        };
        return translatedAlert;
      });
    },
    formatDuration(duration) {
      if (duration > 60) {
        const hours = Math.floor(duration / 60);
        const minutes = Math.floor(duration % 60);
        if (minutes === 0) {
          return `${hours} hora${hours !== 1 ? "s" : ""}`;
        }
        return `${hours} hora${hours !== 1 ? "s" : ""} ${minutes} minuto${
          minutes !== 1 ? "s" : ""
        }`;
      } else {
        return `${duration.toFixed(0)} minuto${duration !== 1 ? "s" : ""}`;
      }
    },
    getTextFromAlert(alert) {
      let severityLevel;
      if (alert.alerts && alert.alerts.length > 0) {
        const mostRecentAlert = alert.alerts[alert.alerts.length - 1];
        severityLevel = mostRecentAlert.severity;
      } else {
        severityLevel =
          alert.severity || this.getDefaultSeverity(alert.alertType);
      }

      let baseMessage = {
        name: alert.name,
        severityLevel,
        system: this.getSystemType(alert.alertType),
        mainText: "",
        subText: "",
      };

      // Helper function to get temperature trend description
      const getTempTrendDesc = (trend) => {
        if (!trend) return "";
        return trend > 0 ? "en aumento" : "en descenso";
      };

      // Get severity description
      const getSeverityDesc = (level) => {
        switch (level) {
          case 3:
            return "Crítico";
          case 2:
            return "Advertencia";
          case 1:
            return "Atención";
          default:
            return "Información";
        }
      };

      switch (alert.alertType) {
        case "ambient_high_temp":
          return {
            ...baseMessage,
            system: "Control de Temperatura",
            mainText: `${getSeverityDesc(
              severityLevel
            )}: Temperatura ambiente elevada ${getTempTrendDesc(
              alert.trend
            )} (${alert.value}°C)`,
            subText:
              severityLevel === 3
                ? "Sistema no responde a comandos de enfriamiento. Requiere atención inmediata."
                : severityLevel === 2
                ? "Temperatura significativamente por encima del punto de ajuste. Verificar funcionamiento."
                : "Temperatura ligeramente elevada. Monitoreando respuesta del sistema.",
          };

        case "ambient_low_temp":
          return {
            ...baseMessage,
            system: "Control de Temperatura",
            mainText: `${getSeverityDesc(
              severityLevel
            )}: Temperatura ambiente baja ${getTempTrendDesc(alert.trend)} (${
              alert.value
            }°C)`,
            subText:
              severityLevel === 3
                ? "Sistema no responde a comandos de calefacción. Requiere atención inmediata."
                : severityLevel === 2
                ? "Temperatura significativamente por debajo del punto de ajuste. Verificar funcionamiento."
                : "Temperatura ligeramente baja. Monitoreando respuesta del sistema.",
          };

        case "difusor_high_temp":
          return {
            ...baseMessage,
            system: "Difusor",
            mainText: `${getSeverityDesc(
              severityLevel
            )}: Temperatura del difusor elevada (${alert.value}°C)`,
            subText:
              severityLevel === 3
                ? "Temperatura crítica en el difusor. Posible fallo en el sistema de enfriamiento."
                : severityLevel === 2
                ? "Temperatura del difusor por encima del rango esperado. Verificar flujo de aire."
                : "Temperatura del difusor ligeramente elevada. Monitoreando condiciones.",
          };

        case "difusor_low_temp":
          return {
            ...baseMessage,
            system: "Difusor",
            mainText: `${getSeverityDesc(
              severityLevel
            )}: Temperatura del difusor baja (${alert.value}°C)`,
            subText:
              severityLevel === 3
                ? "Temperatura crítica en el difusor. Posible fallo en el sistema de calefacción."
                : severityLevel === 2
                ? "Temperatura del difusor por debajo del rango esperado. Verificar flujo de aire."
                : "Temperatura del difusor ligeramente baja. Monitoreando condiciones.",
          };

        default:
          return {
            ...baseMessage,
            system: this.getSystemType(alert.alertType) || "Sistema HVAC",
            mainText: `${getSeverityDesc(
              severityLevel
            )}: ${alert.alertType.replace(/_/g, " ")} (${alert.value}°C)`,
            subText:
              alert.message ||
              "Alerta del sistema en monitoreo. Verificar valores y funcionamiento.",
          };
      }
    },
    getSystemType(alertType) {
      if (alertType.startsWith("ambient")) {
        return "Control de Temperatura";
      } else if (alertType.startsWith("difusor")) {
        return "Difusor";
      }
      return "Sistema HVAC";
    },
    getDefaultSeverity(alertType) {
      let severity;
      switch (alertType) {
        case "difusor_high_temp":
        case "difusor_low_temp":
          severity = 3;
          break;
        case "inefficiency_cool":
        case "inefficiency_heat":
          severity = 2;
          break;
        case "ambient_high_temp":
        case "ambient_low_temp":
          severity = 1;
          break;
        default:
          severity = 1;
      }
      return severity;
    },
    getIconFromAlertType(alertType, severity = 1) {
      if (severity === 3) return "exclamation-triangle";
      if (severity === 2) return "exclamation-triangle";
      return "exclamation-triangle";
    },
    getIconBackgroundFromAlertType(alertType, severity = 1) {
      const severityColors = {
        1: "bg-yellow-400",
        2: "bg-orange-400",
        3: "bg-red-400",
      };

      if (
        alertType === "difusor_high_temp" ||
        alertType === "difusor_low_temp"
      ) {
        return severityColors[severity] || "bg-red-400";
      }

      return severityColors[severity] || "bg-yellow-400";
    },
    startPolling() {
      if (this.pollInterval) {
        clearInterval(this.pollInterval);
      }
      this.pollInterval = setInterval(async () => {
        if (!this.isLoading) {
          await this.loadAlerts();
        }
      }, 30000);
    },
    stopPolling() {
      clearInterval(this.pollInterval);
    },
    async updateAlert(id, timestamp, confirmed) {
      const data = { timestamp: timestamp, correct: confirmed };
      await this.$store.dispatch("updateActuatorAlert", { id: id, data: data });
      this.loadAlerts();
    },
    updateSelection(selection) {
      this.selectedActuatorsIds = selection.map((act) => act.id);
    },
    getMenuPosition(eventIdx) {
      const button = this.$el.querySelector(
        `button[data-event-idx="${eventIdx}"]`
      );
      if (!button) return { top: 0, left: 0 };

      const rect = button.getBoundingClientRect();
      return {
        top: rect.bottom + window.scrollY,
        left: rect.right - 192, // 192px is the width of the menu (w-48)
      };
    },
    toggleMenu(eventIdx) {
      this.filteredAlerts.forEach((alert, idx) => {
        if (idx !== eventIdx) {
          alert.showMenu = false;
        }
      });
      this.$nextTick(() => {
        this.filteredAlerts[eventIdx].showMenu =
          !this.filteredAlerts[eventIdx].showMenu;
      });
    },
    async deleteAlert(id, alerts) {
      await this.$store.dispatch("deleteActuatorAlert", { id, alerts });
      this.loadAlerts();
    },
    async markAsUnresolved(id, timestamp) {
      const data = { timestamp, correct: null };
      await this.$store.dispatch("updateActuatorAlert", { id, data });
      this.loadAlerts();
    },
    handleClickOutside(event) {
      const isMenuClick = event.target.closest(".fixed.z-50");
      const isButtonClick = event.target.closest("button[data-event-idx]");

      if (!isMenuClick && !isButtonClick) {
        this.closeAllMenus();
      }
    },
    closeAllMenus() {
      this.alerts.forEach((alert) => {
        alert.showMenu = false;
      });
    },
    changeTab(tab) {
      this.currentTab = tab;
      this.closeAllMenus();
    },
    toggleSeverity(severityId) {
      const index = this.selectedSeverities.indexOf(severityId);
      if (index === -1) {
        this.selectedSeverities.push(severityId);
      } else {
        this.selectedSeverities.splice(index, 1);
      }
    },
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}
</style>
