<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-10" @close="closeModal">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-2xl"
            >
              <div class="sm:flex sm:items-start">
                <div class="flex w-full flex-col divide-y text-left">
                  <div class="flex items-center justify-between px-4 py-3">
                    <DialogTitle
                      as="h3"
                      class="text-base font-semibold text-gray-900"
                      >{{ actuatorName }}</DialogTitle
                    >
                    <button type="button" @click="closeModal">
                      <font-awesome-icon
                        icon="xmark"
                        class="h-5 w-5 text-gray-400 hover:text-gray-500"
                      />
                    </button>
                  </div>
                  <div class="bg-gray-50 px-4 py-3">
                    <div class="flex items-center justify-between space-x-4">
                      <div class="relative w-64">
                        <select
                          v-model="selectedFilter"
                          class="block w-full cursor-pointer rounded-md border-0 bg-white py-1.5 pl-3 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:ring-2 focus:ring-blue-600"
                        >
                          <option value="all">Todos los eventos</option>
                          <option value="power">Encendido/Apagado</option>
                          <option value="mode">Cambios de modo</option>
                          <option value="temperature">
                            Cambios de temperatura
                          </option>
                          <option value="connection">Estado de conexión</option>
                          <option value="defrost">Modo descongelado</option>
                        </select>
                        <div
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                          <font-awesome-icon
                            icon="chevron-down"
                            class="h-4 w-4 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>

                      <div class="flex space-x-2">
                        <button
                          v-for="period in timePeriods"
                          :key="period.value"
                          :class="[
                            'rounded-md px-4 py-1.5 text-sm font-medium transition-colors',
                            selectedPeriod === period.value
                              ? 'bg-blue-100 text-blue-700 ring-1 ring-blue-700/10'
                              : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900',
                          ]"
                          @click="selectedPeriod = period.value"
                        >
                          {{ period.label }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="max-h-[20rem] w-full overflow-auto px-4">
                    <div
                      v-if="isLoading"
                      class="my-6 flex items-center justify-center"
                    >
                      <font-awesome-icon
                        icon="spinner"
                        class="fa-spin h-5 w-5 text-gray-400"
                      />
                    </div>
                    <div
                      v-else-if="filteredTimeline.length === 0"
                      class="my-6 flex flex-col items-center justify-center text-gray-500"
                    >
                      <font-awesome-icon icon="inbox" class="mb-2 h-6 w-6" />
                      <p class="text-sm">No hay eventos disponibles</p>
                    </div>
                    <ul v-else role="list" class="py-3">
                      <li
                        v-for="(event, eventIdx) in filteredTimeline"
                        :key="eventIdx"
                      >
                        <div class="relative pb-6">
                          <span
                            v-if="eventIdx !== filteredTimeline.length - 1"
                            class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                            aria-hidden="true"
                          />
                          <div class="relative flex space-x-3">
                            <div>
                              <span
                                :class="[
                                  event.iconBackground,
                                  'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white',
                                ]"
                              >
                                <font-awesome-icon
                                  :icon="event.icon"
                                  class="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                            <div
                              class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5"
                            >
                              <div>
                                <p class="text-sm text-gray-500">
                                  {{ event.text }}
                                </p>
                                <p
                                  class="mt-1 text-xs font-light text-gray-400"
                                >
                                  {{ event.author }}
                                </p>
                              </div>
                              <div
                                class="whitespace-nowrap text-right text-sm text-gray-500"
                              >
                                <time>{{ event.date }}</time>
                                <time class="block">{{ event.time }}</time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  data() {
    return {
      timeline: [],
      isLoading: false,
      selectedFilter: "all",
      selectedPeriod: "24h",
      timePeriods: [
        { label: "24h", value: "24h" },
        { label: "7 días", value: "7d" },
        { label: "30 días", value: "30d" },
      ],
    };
  },
  computed: {
    isOpen() {
      return this.$store.state.isActuatorLogsModalOpen;
    },
    actuatorName() {
      return this.$store.state.venueActuators.find(
        (actuator) => actuator.id === this.$store.state.selectedActuatorId
      ).name;
    },
    setpointPresets() {
      return this.$store.state.setpointPresets;
    },
    filteredTimeline() {
      let filtered = [...this.timeline];

      // Filter by time period
      const now = new Date();
      const periodInHours = {
        "24h": 24,
        "7d": 24 * 7,
        "30d": 24 * 30,
      };

      filtered = filtered.filter((event) => {
        if (!event.fullDate) return true;
        const eventDate = new Date(event.fullDate);
        const hoursDiff = (now - eventDate) / (1000 * 60 * 60);
        return hoursDiff <= periodInHours[this.selectedPeriod];
      });

      if (this.selectedFilter === "all") return filtered;

      filtered = filtered.filter((event) => {
        switch (this.selectedFilter) {
          case "power":
            return (
              event.text.includes("encendido") || event.text.includes("apagado")
            );
          case "mode":
            return (
              event.text.includes("modo") ||
              event.text.includes("ventilando") ||
              event.text.includes("calentando") ||
              event.text.includes("enfriando")
            );
          case "temperature":
            return (
              event.text.includes("temperatura") ||
              event.text.includes("setpoint") ||
              event.text.includes("ºC")
            );
          case "connection":
            return (
              event.text.includes("conectado") ||
              event.text.includes("desconectado")
            );
          case "defrost":
            return event.text.includes("descongelado");
          default:
            return true;
        }
      });

      return filtered;
    },
  },
  watch: {
    selectedPeriod(newPeriod) {
      this.loadLogs(newPeriod);
    },
    isOpen: {
      immediate: true,
      async handler(newVal) {
        console.log("Modal open state changed:", newVal);
        if (newVal) {
          await this.loadLogs(this.selectedPeriod);
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.$store.commit("closeActuatorLogsModal");
    },
    async loadLogs(period) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("loadActuatorLogs", {
          actuatorId: this.$store.state.selectedActuatorId,
          period: period,
        });
        const logs = response;
        console.log("Logs loaded:", logs);
        this.timeline = this.translateLogsToTimeline(logs);
      } catch (error) {
        console.error("Error loading logs:", error);
      } finally {
        this.isLoading = false;
      }
    },
    translateLogsToTimeline(logs) {
      if (!logs || logs.length === 0) {
        return [
          {
            text: "No han habido cambios durante la última semana",
            date: "",
            time: "",
            icon: "file",
            iconBackground: "bg-blue-400",
            author: "",
          },
        ];
      }

      logs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      return logs.map((log) => {
        const date = new Date(log.timestamp);
        return {
          text: this.getTextFromLog(log),
          fullDate: log.timestamp,
          date: date.toLocaleString("es-ES", {
            month: "short",
            day: "2-digit",
          }),
          time: date.toLocaleString("es-ES", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }),
          icon: this.getIconFromLog(log),
          iconBackground: this.getIconBackgroundFromLog(log),
          author:
            log.author != "Flair" && log.author
              ? `Modificado por ${log.author}`
              : "Cambio autónomo Flair",
        };
      });
    },
    getTextFromLog(log) {
      let presetName;

      switch (log.keyChanged) {
        case "defrost_mode":
          return `Modo descongelado ${
            log.newValue ? "activado" : "desactivado"
          }`;
        case "unit_power":
          return log.newValue ? "Equipo encendido" : "Equipo apagado";
        case "unit_mode":
          switch (log.newValue) {
            case "fan":
            case "ventilation":
              return "Equipo ventilando";
            case "heat":
            case "heating":
              return "Equipo calentando";
            case "cool":
            case "cooling":
              return "Equipo enfriando";
            case "off":
            case "stopped":
              return "Equipo apagado";
            default:
              return `Modo del equipo cambiado a ${log.newValue}`;
          }
        case "online":
          return log.newValue ? "Equipo conectado" : "Equipo desconectado";
        case "setpoint_temp_low":
          return `Límite inferior cambiado a ${log.newValue}ºC`;
        case "setpoint_temp_high":
          return `Límite superior cambiado a ${log.newValue}ºC`;
        case "current_setpoint_preset":
          presetName = this.$store.getters.getPresetName(log.newValue);
          return `Preset cambiado a ${presetName || "Desconocido"}`;
        case "automatic_control":
          return log.newValue
            ? "Control automático activado"
            : "Control automático desactivado";
        case "control_cycle_mode":
          return log.newValue == "eco"
            ? "Modo de ciclo de control cambiado a Flair Eco"
            : "Modo de ciclo de control cambiado a Flair";
        case "dynamic_cool_setpoint":
          return `Setpoint de enfriamiento ajustado a ${log.newValue}ºC`;
        case "dynamic_heat_setpoint":
          return `Setpoint de calentamiento ajustado a ${log.newValue}ºC`;
        case "target_setpoint":
          return `Temperatura objetivo ajustada a ${log.newValue}ºC`;
        default:
          return "Acción desconocida";
      }
    },
    getIconFromLog(log) {
      switch (log.keyChanged) {
        case "defrost_mode":
          return "snowflake";
        case "unit_power":
          return "power-off";
        case "unit_mode":
          switch (log.newValue) {
            case "fan":
            case "ventilation":
              return "fan";
            case "heat":
            case "heating":
              return "fire";
            case "cool":
            case "cooling":
              return "snowflake";
            case "off":
            case "stopped":
              return "power-off";
            default:
              return "question";
          }
        case "online":
          return log.newValue ? "plug-circle-check" : "plug-circle-xmark";
        case "setpoint_temp_low":
          return "temperature-arrow-down";
        case "setpoint_temp_high":
          return "temperature-arrow-up";
        case "setpoint_mode":
          switch (log.newValue) {
            case "manual":
              return "user-pen";
            case "preset":
              return "list-check";
            default:
              return "question";
          }
        case "current_setpoint_preset":
          return "temperature-half";
        case "automatic_control":
          return "cog";
        case "control_cycle_mode":
          return "leaf";
        case "dynamic_cool_setpoint":
          return "temperature-arrow-down";
        case "dynamic_heat_setpoint":
          return "temperature-arrow-up";
        case "target_setpoint":
          return "temperature-half";
        default:
          return "circle-info";
      }
    },
    getIconBackgroundFromLog(log) {
      const colors = {
        success: "bg-emerald-400",
        warning: "bg-amber-400",
        info: "bg-blue-400",
        error: "bg-red-400",
        neutral: "bg-gray-400",
        highlight: "bg-violet-400",
        defrost: "bg-yellow-400",
      };

      switch (log.keyChanged) {
        case "defrost_mode":
          return colors.defrost;
        case "unit_power":
          return log.newValue ? colors.success : colors.neutral;
        case "unit_mode":
          switch (log.newValue) {
            case "fan":
              return colors.success;
            case "heat":
              return colors.warning;
            case "cool":
              return colors.info;
            default:
              return colors.neutral;
          }
        case "online":
          return log.newValue ? colors.success : colors.error;
        case "setpoint_temp_low":
        case "setpoint_temp_high":
        case "setpoint_mode":
        case "current_setpoint_preset":
          return colors.highlight;
        case "automatic_control":
          return log.newValue ? colors.success : colors.neutral;
        case "control_cycle_mode":
          return log.newValue == "eco" ? colors.success : colors.neutral;
        case "dynamic_cool_setpoint":
          return colors.info;
        case "dynamic_heat_setpoint":
          return colors.warning;
        case "target_setpoint":
          return colors.highlight;
        default:
          return colors.info;
      }
    },
  },
};
</script>
