<template>
  <div class="flex justify-center gap-2 sm:flex">
    <variable-chart
      class="w-full"
      :variable="variables[0].variable"
      :humanized="variables[0].humanized"
      :unit="variables[0].unit"
      :data="chartData"
      :threshold="700"
      :threshold-color="'#EF4444'"
      :value-color="(value) => (value > 700 ? '#EF4444' : '#3B82F6')"
      :y-axis-max="1000"
      :threshold-dash="[5, 5]"
    />
  </div>
</template>

<script>
import VariableChart from "../cards/VariableChart.vue";

export default {
  name: "DistanceMeasurementsChart",
  components: {
    VariableChart,
  },
  props: {
    sensorId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      variables: [{ variable: "distance", humanized: "Distancia", unit: "mm" }],
      historicalData: [],
    };
  },
  computed: {
    chartData() {
      return this.historicalData.map((reading) => ({
        date: reading.timestamp,
        value: reading.distance,
      }));
    },
  },
  async created() {
    try {
      await this.$store.dispatch("selectSensor", { id: this.sensorId });
      await this.$store.dispatch("loadSensorQueryData", {
        sensor: { pk: this.sensorId },
      });
      this.historicalData = this.$store.getters.sensorQueryDataArray;
    } catch (error) {
      console.error("Error loading historical data:", error);
    }
  },
};
</script>
