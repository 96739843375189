<template>
  <div class="relative h-full w-full">
    <div id="charts-wrapper" class="h-full w-full">
      <div :class="`${canvasName}-container relative h-full w-full`">
        <div class="absolute ml-20 mt-12 flex flex-row text-gray-500">
          <button class="pr-1" @click="zoomIn">
            <font-awesome-icon icon="search-plus" class="h-4 w-4" />
          </button>
          <button class="pl-1" @click="zoomOut">
            <font-awesome-icon icon="search-minus" class="h-4 w-4" />
          </button>
        </div>
        <canvas :id="canvasName" class="h-full w-full max-w-full" />
      </div>
    </div>
  </div>
</template>

<script>
import "chartjs-adapter-moment";
import {
  CategoryScale,
  Chart,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from "chart.js";
import _ from "lodash";
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
Chart.register(
  CategoryScale,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  annotationPlugin,
  zoomPlugin
);

export default {
  name: "VariableChart",
  props: {
    variable: {
      type: String,
      default: "",
    },
    humanized: {
      type: String,
      default: "",
    },
    unit: {
      type: String,
      default: "",
    },
    threshold: {
      type: Number,
      default: null,
    },
    thresholdColor: {
      type: String,
      default: "#EF4444",
    },
    thresholdLabel: {
      type: String,
      default: null,
    },
    thresholdDash: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    canvasName() {
      return this.variable + "-chart";
    },
    isSuperuser() {
      return this.$store.state.user.isSuperuser;
    },
    variableTimeSeries() {
      return this.$store.state[this.variable + "TimeSeries"];
    },
    zoomLimit() {
      return [
        this.variableTimeSeries.data[0].date,
        _.last(this.variableTimeSeries.data).date,
      ];
    },
    chartOptions() {
      const options = {
        animation: {
          duration: 0,
        },
        elements: {
          line: {
            borderWidth: 2,
            fill: true,
          },
          point: {
            radius: 0,
          },
        },
        scales: {
          x: {
            type: "time",
            tooltipFormat: "HH:mm",
            time: {
              displayFormats: {
                hour: "h a",
                minute: "HH:mm",
                second: "HH:mm:ss",
              },
            },
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: "Hora",
            },
          },
          y: {
            grid: {
              type: "linear",
              grace: "1",
              display: false,
            },
            suggestedMin:
              this.variable == "co2"
                ? 400
                : this.variable == "temperature"
                ? 19
                : this.variable == "humidity"
                ? 30
                : null,
            suggestedMax:
              this.variable == "co2"
                ? 800
                : this.variable == "temperature"
                ? 23
                : this.variable == "humidity"
                ? 70
                : null,

            title: {
              display: true,
              text: `${this.humanized} (${this.unit})`,
            },
          },
        },
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
            },
            limits: {
              x: { min: "original", max: "original" },
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              mode: "x",
            },
          },
          title: {
            display: true,
            text: this.humanized,
            font: {
              size: 18,
            },
          },
          annotation: {
            annotations: this.threshold
              ? {
                  threshold: {
                    type: "line",
                    yMin: this.threshold,
                    yMax: this.threshold,
                    borderColor: this.thresholdColor,
                    borderWidth: 2,
                    borderDash: this.thresholdDash,
                    label: this.thresholdLabel
                      ? {
                          content: this.thresholdLabel,
                          enabled: true,
                          position: "end",
                        }
                      : undefined,
                  },
                }
              : {},
          },
          tooltip: {
            intersect: false,
            displayColors: false,
            callbacks: {
              title: (tooltipItems) =>
                "Hora: " + tooltipItems[0].raw.x.format("HH:mm"),
              label: (tooltipItem) =>
                `${this.humanized} : ${tooltipItem.parsed.y} ${this.unit}`,
            },
          },
        },
      };

      return options;
    },
  },
  watch: {
    variableTimeSeries: {
      deep: true,
      handler() {
        this.$nextTick(() => this.plotVariable());
      },
    },
  },
  mounted() {
    this.$nextTick(() => this.plotVariable());
  },
  methods: {
    zoomIn() {
      let chart = Chart.getChart(this.canvasName);
      chart?.zoom(1.1);
    },
    zoomOut() {
      let chart = Chart.getChart(this.canvasName);
      chart?.zoom(0.9);
    },
    plotVariable() {
      Chart.getChart(this.canvasName)?.destroy();
      const timeSeries = this.variableTimeSeries.data.map(
        ({ date, value }) => ({ x: date, y: value })
      );
      let plotDatasets = [
        {
          data: timeSeries,
          backgroundColor: this.setOpacity(this.colors.productivity, 0.1),
          borderColor: this.setOpacity(this.colors.productivity, 0.8),
          yAxisID: "y",
        },
      ];

      let canvasElement = document.getElementById(this.canvasName);
      if (!canvasElement || !canvasElement.getContext) {
        return;
      }
      const ctx = canvasElement.getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: {
          datasets: plotDatasets,
        },
        options: this.chartOptions,
      });
    },
  },
};
</script>

<style scoped>
#charts-wrapper {
  height: 100%;
  width: 100%;
}
canvas {
  height: 100% !important;
  width: 100% !important;
}
</style>
