<template>
  <div data-cy="venues-dropdown" class="relative flex">
    <a
      class="flex max-w-[200px] cursor-pointer flex-row items-center gap-1 rounded-lg p-2 text-sm font-medium"
      :class="{ 'bg-flairblue text-white': show }"
      @mouseover="open"
      @mouseleave="close"
      @click="toggleShow"
    >
      <span class="block truncate">
        {{ text }}
      </span>
      <font-awesome-icon
        icon="angle-up"
        class="h-4 w-4 flex-shrink-0 transform transition-transform duration-200"
        :class="{ 'rotate-180': show }"
      />
    </a>
    <div
      v-show="show"
      class="absolute left-0 z-10 mt-8 min-w-[280px] origin-top-left rounded-md pt-4 md:min-w-[320px]"
      @mouseover="open"
      @mouseleave="close"
    >
      <div
        class="max-h-96 cursor-pointer overflow-auto rounded-md bg-white p-2 py-1 shadow-md"
      >
        <div class="relative mb-2 w-full">
          <input
            v-model="searchTerm"
            type="text"
            placeholder="Buscar..."
            class="w-full rounded-md border p-2 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <font-awesome-icon
            icon="magnifying-glass"
            class="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 transform text-gray-400"
          />
        </div>
        <slot :toggleShow="toggleShow" :searchTerm="searchTerm" />
      </div>
    </div>
  </div>
</template>

<script>
import { showMixin } from "../../mixins";
export default {
  name: "NavbarDropdown",
  mixins: [showMixin],
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchTerm: "",
    };
  },
};
</script>
